import ErrorMessages from 'enums/ErrorMessages';
import RequestError from 'errors/RequestError';
import { failureNotification } from 'notifications';
import { Environments, getEnvironment } from 'utils/getEnvironment';
import sleep from 'utils/sleep';
import { getRedirectPathForLogin } from 'utils/loginRedirectUtils';

export type AccessTokenGetter = () => Promise<string | null>;

interface FetchResponse<T = any> extends Response {
  json<P = T>(): Promise<P>;
}

export interface IRequestErrors extends Error {
  response?: FetchResponse;
}

export enum FetchMethodType {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum ErrorMode {
  HideError,
  DisplayGenericMessage,
  DisplayActualMessage,
}

export interface IFetchOptions {
  headers?: Headers;
  resetDefaultHeaders?: boolean;
  method?: FetchMethodType;
  body?: any;
  statusChecker?: (res: Response, errorMode: ErrorMode) => Promise<FetchResponse<Body>>;
  errorMode?: ErrorMode;
  maxRetries?: number;
  retries?: number;
}

const checkStatus = async (response: FetchResponse, errorMode: ErrorMode, value: any): Promise<FetchResponse> => {
  if (response.ok) {
    return response;
  }

  let errorMessage = null;
  try {
    if (value) {
      // Avoids parsing a null body to json

      if (errorMode !== ErrorMode.DisplayActualMessage && getEnvironment() === Environments.Production) {
        errorMessage = ErrorMessages.Default;
      } else {
        errorMessage =
          (typeof value === 'string'
            ? value
            : value.message || value.error || value.data?.error || value.error?.message) || response.statusText;
      }

      if (errorMode !== ErrorMode.HideError) {
        failureNotification(errorMessage || ErrorMessages.Default);
      }
    }
  } catch (error) {
    if (errorMode !== ErrorMode.HideError) {
      failureNotification(ErrorMessages.Default);
    }
  }

  throw new RequestError(errorMessage, response.status, value);
};

const getBasicHeaders = () => {
  const headers = new Headers();

  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  return headers;
};

const getLastStreamMessage = (value: Uint8Array): any => {
  const decoder = new TextDecoder();
  const message = decoder.decode(value);

  const array = message.split('\n').filter((item) => item !== '');
  const lastMessage = array[array.length - 1];

  return JSON.parse(lastMessage);
};

export default class Api {
  constructor(private baseUrl?: string) {}

  protected async fetch<Body>(url: string, options?: IFetchOptions): Promise<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
      statusChecker = checkStatus,
      errorMode = ErrorMode.DisplayGenericMessage,
      retries = 0,
      maxRetries = 2,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    try {
      const userData = await fetch(this.baseUrl ? `${this.baseUrl}${url}` : url, {
        method,
        headers,
        body: body instanceof FormData ? body : JSON.stringify(body),
      });

      let value = null;
      try {
        value = await userData.json();
      } catch (err: unknown) {
        value = await userData.text();
        console.warn(`Value is not JSON: ${value}`);
      }

      await statusChecker(userData, errorMode, value);

      // TODO: can't handle HTTP 204 No Content, because it tries to convert an empty body to json!
      return value;
    } catch (err: unknown) {
      const { responseStatus: statusCode } = err as { responseStatus?: number };
      if (statusCode === 401 || statusCode === 403) {
        window.location.href = getRedirectPathForLogin();
      }
      if (
        retries <= maxRetries &&
        statusCode !== undefined &&
        (statusCode === 429 || statusCode === 0 || statusCode >= 500)
      ) {
        await sleep(2 ** retries * 200); // exponential backoff
        return this.fetch(url, { ...options, retries: retries + 1 });
      }
      throw err;
    }
  }

  protected async *fetchStream<Body>(url: string, options?: IFetchOptions): AsyncGenerator<Body> {
    const {
      headers: customHeaders,
      method = FetchMethodType.GET,
      body,
      resetDefaultHeaders,
      statusChecker = checkStatus,
      errorMode = ErrorMode.DisplayGenericMessage,
      retries = 0,
      maxRetries = 2,
    } = options || {};

    const headers = resetDefaultHeaders ? new Headers() : getBasicHeaders();

    if (customHeaders) {
      customHeaders.forEach((value: string, header: string) => {
        headers.set(header, value);
      });
    }

    try {
      const response = await fetch(this.baseUrl ? `${this.baseUrl}${url}` : url, {
        method,
        headers,
        body: body instanceof FormData ? body : JSON.stringify(body),
      });

      const reader = response.body!.getReader();

      let prev = await reader.read();
      let done = false;
      while (!done) {
        // eslint-disable-next-line no-await-in-loop
        const next = await reader.read();
        if (!next.done) {
          if (prev.value) {
            yield getLastStreamMessage(prev.value);
          }
          prev = next;
        }
        done = next.done;
      }

      const value = getLastStreamMessage(prev.value!);
      await statusChecker(response, errorMode, value);
      yield value;
    } catch (err: unknown) {
      const { responseStatus: statusCode } = err as { responseStatus?: number };
      if (
        retries <= maxRetries &&
        statusCode !== undefined &&
        (statusCode === 429 || statusCode === 0 || statusCode >= 500)
      ) {
        await sleep(2 ** retries * 200); // exponential backoff
        yield* this.fetchStream(url, { ...options, retries: retries + 1 });
      }
      throw err;
    }
  }
}
