import React, { useEffect } from 'react';
import Button from 'components/Button';
import Input from 'components/Input';
import FormContainer from 'components/LoanForm/FormContainer';
import { useForm } from 'react-hook-form';
import { getMessageForRequiredFields } from 'utils/errors';
import FormNavigation from 'components/FormNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setYourIncomeData } from 'handlers/yourIncome';
import { getApplicationStep } from 'selectors/getApplicationStep';
import { CurrentFlow } from 'enums/CurrentFlow';
import { YourIncomeVariable } from 'enums/LoanFormVariables';
import { FlowComponentType } from 'routes/FlowRouter';
import NumberInput from 'components/NumberInput/NumberInput';
import { ANNUAL_INCOME_MAX_LENGTH } from 'components/LoanForm/YourTotalIncome/YourTotalIncome';
import { formatDate, getYearsFromNow } from 'utils/dateUtils';
import DatePicker from 'components/DatePicker/DatePicker';
import { getYourIncome } from 'selectors/yourIncome';
import { getPartnerFromEmployerName, PARTNER_DATA } from 'enums/PartnerName';
import { PreQualificationData, setPartnerName } from 'handlers/preQualificationData';
import { getPreQualificationData } from 'selectors/preQualificationData';
import InputSelect from 'components/InputSelect';

import styles from './YourIncome.module.scss';

enum YourIncomeInputLabel {
  GrossAnnualIncome = 'Gross annual income',
  StartOfEmployment = 'Start date of primary employer',
  EmployerName = 'Primary employer name',
  JobTitle = 'Job title',
}

const YourIncome = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();

  const { currentFlow } = useSelector(getApplicationStep);
  const { partnerName, benefit } = useSelector(getPreQualificationData);
  const defaultValues = useSelector(getYourIncome);

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      ...(partnerName && { employer_name: PARTNER_DATA[partnerName].fullName }),
    },
  });

  const watcher = watch();

  const benefitEligibleTitles =
    (benefit && partnerName ? PARTNER_DATA[partnerName]?.benefitEligibleTitles : null) ?? [];

  const shouldCollectJobTitle = benefitEligibleTitles.length > 0;

  const checkForPartner = (employerName: string) => {
    const partner = getPartnerFromEmployerName(employerName);
    if (partner) {
      const preQualificationData: PreQualificationData = {
        partnerName: partner,
      };

      dispatch(setPartnerName(preQualificationData));
    }
  };

  const handleContinue = () => {
    dispatch(setYourIncomeData(watcher));
    checkForPartner(watcher[YourIncomeVariable.EmployerName] as string);
    handleNext();
  };

  useEffect(() => {
    register(YourIncomeVariable.TotalAnnualIncome, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.GrossAnnualIncome),
    });
    register(YourIncomeVariable.StartOfEmployment, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.StartOfEmployment),
    });
    register(YourIncomeVariable.EmployerName, {
      required: getMessageForRequiredFields(YourIncomeInputLabel.EmployerName),
    });
    if (shouldCollectJobTitle) {
      register(YourIncomeVariable.JobTitle, {
        required: getMessageForRequiredFields(YourIncomeInputLabel.JobTitle),
      });
    }
  }, [register]);

  let title = '';
  let subtitle = '';

  switch (currentFlow) {
    case CurrentFlow.Card:
      title = 'What’s your income?';
      subtitle = 'This information is needed to put together your offer.';
      break;
    default:
      title = 'What’s your income?';
      subtitle = 'This will help us calculate your results.';
      break;
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title={title} subtitle={subtitle}>
        <NumberInput
          label={YourIncomeInputLabel.GrossAnnualIncome}
          prefix="$"
          placeholder="$0"
          errorMessage={errors[YourIncomeVariable.TotalAnnualIncome]?.message}
          thousandSeparator
          name={YourIncomeVariable.TotalAnnualIncome}
          onChange={(event) => {
            setValue(YourIncomeVariable.TotalAnnualIncome, Number(event.target.value.replace(/[^0-9.-]+/g, '')));
            trigger(YourIncomeVariable.TotalAnnualIncome);
          }}
          value={
            watcher[YourIncomeVariable.TotalAnnualIncome]
              ? `${watcher[YourIncomeVariable.TotalAnnualIncome]}`
              : undefined
          }
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
          autoFocus
        />
        <div className={styles.note}>
          Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
          considered as a basis for repaying this obligation.
        </div>
        <Input
          label={YourIncomeInputLabel.EmployerName}
          className={styles.formInput}
          onChange={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value);
            trigger(YourIncomeVariable.EmployerName);
          }}
          onBlur={(event) => {
            setValue(YourIncomeVariable.EmployerName, event.target.value.trim());
            trigger(YourIncomeVariable.EmployerName);
            checkForPartner(event.target.value.trim());
          }}
          placeholder="Employer name"
          value={watcher[YourIncomeVariable.EmployerName]}
          errorMessage={errors[YourIncomeVariable.EmployerName]?.message}
          onKeyUp={(e) => e.key === 'Enter' && isValid && handleContinue()}
        />
        {shouldCollectJobTitle && (
          <InputSelect
            onChange={(option) => {
              setValue(YourIncomeVariable.JobTitle, option.value);
              trigger(YourIncomeVariable.JobTitle);
            }}
            className={styles.inputContainer}
            placeholder="Select"
            value={watcher[YourIncomeVariable.JobTitle]}
            label={YourIncomeInputLabel.JobTitle}
            onBlur={(event) => {
              setValue(event.target.name as YourIncomeVariable, event.target.value);
              trigger(event.target.name as YourIncomeVariable);
            }}
            options={[
              ...benefitEligibleTitles.map((item) => ({ label: item, value: item })),
              { label: 'Other', value: 'Other' },
            ]}
          />
        )}
        <DatePicker
          maxDate={getYearsFromNow(0)}
          minDate={getYearsFromNow(-30)}
          placeHolder="Month YYYY"
          label={YourIncomeInputLabel.StartOfEmployment}
          className={styles.formInput}
          showMonthYearPicker
          selected={
            watcher[YourIncomeVariable.StartOfEmployment]
              ? new Date(watcher[YourIncomeVariable.StartOfEmployment] as string)
              : undefined
          }
          onChange={(date) => {
            setValue(YourIncomeVariable.StartOfEmployment, formatDate(date));
            trigger(YourIncomeVariable.StartOfEmployment);
          }}
          errorMessage={errors[YourIncomeVariable.StartOfEmployment]?.message}
          name={YourIncomeVariable.StartOfEmployment}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && isValid && handleContinue()}
        />

        <Button className={styles.button} disabled={!isValid} onClick={handleContinue}>
          Apply
        </Button>
      </FormContainer>
    </>
  );
};

export default YourIncome;
