import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createDocuSignEnvelope, getStudentLoanApplication as getStudentLoanApplicationThunk } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { getApplicationData } from 'selectors/getApplicationData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as SignIcon } from 'images/sign-icon.svg';
import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';
import { useLocation } from 'react-router-dom';
import { StepsResult } from 'enums/FlowNextResults';

import styles from './ServiceAgreement.module.scss';

const ServiceAgreement = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);

  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const [isLoading, setIsLoading] = useState(false);
  const { isLastStep, handleNextStep } = useApplySteps();

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/sl-service-agreement-done') {
      handleNextStep(StepsResult.Completed);
    }
  }, [applicationId]);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId }));
      })();
  }, []);

  const handleContinue = async () => {
    analytics.track('Sign Agreement Clicked');
    setIsLoading(true);

    window.location.replace(
      await dispatchWithUnwrap(
        createDocuSignEnvelope({
          applicationId: applicationId!,
          windowLocationHref: window.location.href,
          document: DocuSignDocument.StudentLoanAssistanceServiceAgreement,
        }),
      ),
    );
    setIsLoading(false);
  };

  return (
    <FormContainer
      title={isLastStep ? 'Just one last step...' : 'Sign Agreement'}
      subtitle={
        <>
          <p>
            Click "Sign Agreement" below to be redirected to DocuSign to authorize us to work on your application on
            your behalf.
          </p>
          <p>After you sign, we will begin working on enrolling you in the right plans for you.</p>
        </>
      }
      icon={<SignIcon />}
      className={styles.formContainer}
    >
      {applyProgress?.serviceAgreement && (
        <>
          <div className={styles.uploaded}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Agreement signed</p>
          </div>
        </>
      )}

      {Boolean(!applyProgress?.serviceAgreement) && (
        <Button className={styles.button} onClick={handleContinue} isLoading={isLoading || isApplicationStatusUpdating}>
          Sign Agreement
        </Button>
      )}
    </FormContainer>
  );
};

export default ServiceAgreement;
